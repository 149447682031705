import styled from "@emotion/styled";
import { Colors } from "../../component/Color";
import Typography from "../../component/Typography";

const Container = styled.div`
  padding: 80px 20px;
`;

const Infos = styled.div`
  margin-top: 20px;
`;
const Name = styled.div``;

const Space = styled.div`
  height: 80px;
`;

const Links = styled.div`
  margin-top: 20px;
  a {
    text-decoration: undefline;
    color: ${Colors.gray60};
  }
`;

const Separator = styled.span`
  margin: 0 10px;
`;

export default function CompanyInfo() {
  return (
    <Container>
      <Typography variant="10/bold" color={Colors.gray40}>
        Tenacious inc.
      </Typography>
      <Infos>
        <Typography variant="5/regular" color={Colors.gray40} component="div">
          터네이셔스 주식회사 (Tenacious Inc.)
        </Typography>
        <Typography variant="5/regular" color={Colors.gray40} component="div">
          대표자: 김정훈 최준회ㅣ사업자등록번호: 804-88-03090
        </Typography>
        <Typography variant="5/regular" color={Colors.gray40} component="div">
          주소: 서울특별시 서초구 강남대로 311, 706호
        </Typography>
        <Typography variant="5/regular" color={Colors.gray40} component="div">
          이메일: hello@stocknow.ai
        </Typography>
        <Links>
          <Typography variant="5/regular" color={Colors.gray60} component="div">
            <a href="https://resources.stocknow.ai/privacy/2024-09-10.html" target="_blank" rel="noreferrer">
              개인정보처리방침
            </a>
            <Separator>|</Separator>
            <a href="https://resources.stocknow.ai/termofservice/2024-09-10.html" target="_blank" rel="noreferrer">
              서비스이용약관
            </a>
          </Typography>
        </Links>
      </Infos>
      <Space />
    </Container>
  );
}
